/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export enum AppRoute {
  Base = "/",
  Taskboard = "/taskboard",
  Dashboard = "/dashboard",
  Courses = "/courses",
  Programs = "/programs",
  Free = "/free",
  Forms = "/forms",
  FormInstances = "/formInstances",
  Questions = "/questions",
  Answers = "/answers",
  Modules = "/modules",
  Mentors = "/mentors",
  Users = "/users",
  Teams = "/teams",
  Sessions = "/sessions",
  Students = "/students",
  Tools = "/tools",
  Deliverables = "/deliverables",
  InteractiveContent = "/content",
  CalendarEvents = "/calendar-events",
  Calendar = "/calendar",
  Badges = "/badges",
  Cohorts = "/cohorts",
  Metrics = "/metrics",
  Unauthorized = "/unauthorized",
  Payments = "/payments",
  Reports = "/reports",
  Schools = "/schools",
  Platform = "/platform",
  Gamification = "/gamification",
  Invites = "/invites",
  Certificates = "/certificates",
  Organizations = "/organizations",
  Avatar = "/avatar",
  Notifications = "/notifications",
  Nexus = "/nexus",
  Creator = "/creator",
  Flows = "/flows",
  Blocks = "/blocks",
  Communities = "/communities",
  Onboarding = "/onboarding",
  Surveys = "/surveys",
}

export enum PublicRoute {
  Enroll = "/enroll",
  ValidateEmail = "/validate-email",
  ResetPassword = "/reset-password",
  ForgotPasssword = "/forgot-password",
  ConductCode = "/conduct-code",
  ServiceTerms = "/service-terms",
  PrivacyPolicy = "/privacy-policy",
  OSSAttributions = "/oss-attributions",
  Certifications = "/certifications",
  SchoolNotFound = "/school-not-found",
  CLILogin = "/cli/login",
  SocialShare = "/social/share",
  LiveSession = "/live-session",
  GradingPolicy = "/grading-policy",
  UserSchools = "/user-schools",
}

export const InitialRoute = AppRoute.Courses;

export const isPublicRoute = (pathname: string): boolean =>
  pathname.includes(PublicRoute.Enroll) ||
  pathname.includes(PublicRoute.ValidateEmail) ||
  pathname.includes(PublicRoute.ResetPassword) ||
  pathname.includes(PublicRoute.Certifications) ||
  pathname.includes(PublicRoute.CLILogin) ||
  pathname.includes(PublicRoute.SchoolNotFound) ||
  pathname.includes(PublicRoute.SocialShare) ||
  pathname.includes(PublicRoute.OSSAttributions) ||
  pathname.includes(PublicRoute.LiveSession) ||
  pathname.includes(PublicRoute.UserSchools);
